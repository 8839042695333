import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import tw from 'tailwind.macro'

class InfoTop extends Component {
    renderBlocks = () => {
        const { optionsData } = this.props
        let infoData = undefined
        if (optionsData) {
            infoData = this.props.optionsData.options.info_top
        }

        if (infoData) {
            const blocks = infoData.map((block, i) => {
                switch (block.acf_fc_layout) {
                    case 'text_block':
                        return (
                            <Text
                                key={i}
                                dangerouslySetInnerHTML={{ __html: block.text }}
                            />
                        )
                    case 'link_block':
                        return (
                            <Link
                                key={i}
                                target={block.link.target}
                                href={`${block.link.url}`}
                                dangerouslySetInnerHTML={{
                                    __html: block.link.title,
                                }}
                            />
                        )
                    case 'email_block':
                        return (
                            <Link
                                key={i}
                                href={`mailto:${block.email}`}
                                dangerouslySetInnerHTML={{
                                    __html: block.email,
                                }}
                            />
                        )
                    case 'phone_block':
                        return (
                            <Link
                                key={i}
                                href={`tel:${block.phone_number}`}
                                dangerouslySetInnerHTML={{
                                    __html: block.phone_number,
                                }}
                            />
                        )
                    default:
                        console.log('block not found')
                }
            })

            return <div>{blocks}</div>
        }
    }

    render() {
        return <Wrapper>{this.renderBlocks()}</Wrapper>
    }
}

const Text = styled.p``
const Link = styled.a`
    display: block;
`

const Wrapper = styled.div`
    ${tw`text-center text-xl md:text-lg leading-relaxed`}

    ${Text}, ${Link} {
        ${tw`m-0`}
    }
`

InfoTop.propTypes = {
    optionsData: PropTypes.object,
}

const mapStateToProps = state => ({
    optionsData: state.data.optionsData,
})

export default connect(mapStateToProps)(InfoTop)
