import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { isMobileOnly } from 'react-device-detect'
import posed from 'react-pose'
import poseProps from '../pose-props'

import SEO from '../components/seo'
import InfoTop from '../components/info-top'
import InfoBottom from '../components/info-bottom'
import MobileSplash from '../components/mobile-splash'

class IndexPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
        }
    }

    componentWillMount = () => {
        const { optionsData, pagesData, imagesData, isMobile } = this.props

        // load data if it doesn't exist
        if (!optionsData || !pagesData || !imagesData) {
            const optionsData = JSON.parse(
                this.props.data.wordpressOptions.options
            )
            const pagesData = JSON.parse(this.props.data.wordpressPages.pages)
            const imagesData = JSON.parse(
                this.props.data.wordpressImages.images
            )

            this.props.dispatch({
                type: 'LOAD_DATA',
                optionsData: optionsData,
                pagesData: pagesData,
                imagesData: imagesData,
            })
        }

        if (isMobile !== isMobileOnly) {
            this.props.dispatch({
                type: 'UPDATE_MOBILE',
                isMobile: isMobileOnly
            })
        }
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            window.scrollTo(0, 0)
        }

        this.setState({
            isVisible: true,
        })
    }

    renderNav = () => {
        const { pagesData, optionsData } = this.props

        const pages = pagesData.pages.map((page, i) => {
            return (
                <NavLink key={i} to={`/${page.slug}`}>
                    {page.title}
                </NavLink>
            )
        })

        return (
            <Nav>
                {pages}
                {/* Booking Link */}
                {optionsData && optionsData.options.booking_link && (
                    <ExternalLink
                        href={optionsData.options.booking_link}
                        target="_blank"
                    >
                        Book
                    </ExternalLink>
                )}
            </Nav>
        )
    }

    render() {
        const { pagesData, isMobile } = this.props
        const { isVisible } = this.state

        // console.log(isMobile)

        return (
            <Wrapper>
                <SEO title={'Home'} />

                {/* Content */}
                <PoseContent pose={isVisible ? 'visible' : 'hidden'}>
                    <InfoTop />
                    {pagesData && this.renderNav()}
                    <InfoBottom />
                </PoseContent>

                {/* Mobile Only */}
                {isMobile && <MobileSplash />}
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    ${tw`h-full`}
`

const PoseContent = styled(posed.div(poseProps.content))`
    ${tw`flex flex-col justify-between items-center h-full mx-auto py-6`}
`

const ExternalLink = styled.a``
const NavLink = styled(Link)``
const Nav = styled.nav`
    ${tw`flex flex-col py-10 text-center`}

    ${ExternalLink}, ${NavLink} {
        ${tw`inline-block font-heading uppercase leading-none`}
        font-size: 120px;
        letter-spacing: 3.04px;

        @media (max-width: 640px) {
            font-size: 80px;
        }
    }
`

IndexPage.propTypes = {
    optionsData: PropTypes.object,
    pagesData: PropTypes.object,
    imagesData: PropTypes.object,
    isMobile: PropTypes.bool,
}

const mapStateToProps = state => ({
    optionsData: state.data.optionsData,
    pagesData: state.data.pagesData,
    imagesData: state.data.imagesData,
    isMobile: state.mobile.isMobile,
})

export default connect(mapStateToProps)(IndexPage)

export const pageQuery = graphql`
    query {
        wordpressOptions {
            options
        }
        wordpressPages {
            pages
        }
        wordpressImages {
            images
        }
    }
`
